import React from "react";
import styled from "styled-components";
import "../Anim.css";

function EngineeringTeamAnim() {
  return (
    <div class="SvgAnimContainer" style={{ paddingTop: "20px" }}>
      <svg
        class="SvgAnimContainer"
        width="100%"
        height="100%"
        viewBox="0 0 691 623"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="undraw_engineering_team_u99p (1) 1" clip-path="">
          <path
            id="Vector"
            d="M413.242 418.137H297.732C296.098 418.139 294.532 418.791 293.378 419.948C292.224 421.105 291.576 422.673 291.578 424.307V606.778H419.412V424.307C419.412 423.496 419.253 422.694 418.943 421.945C418.633 421.196 418.178 420.516 417.605 419.943C417.032 419.37 416.352 418.916 415.603 418.606C414.855 418.296 414.052 418.137 413.242 418.137ZM355.876 521.815C352.263 521.798 348.804 520.356 346.249 517.802C343.695 515.247 342.253 511.788 342.236 508.176V487.129C342.236 483.511 343.673 480.042 346.231 477.484C348.789 474.926 352.258 473.489 355.875 473.489C359.493 473.489 362.962 474.926 365.52 477.484C368.078 480.042 369.515 483.511 369.515 487.129V508.176C369.499 511.788 368.056 515.247 365.502 517.802C362.947 520.356 359.488 521.798 355.876 521.815V521.815Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_2"
            d="M291.182 600.942V618.547C291.184 619.459 291.547 620.333 292.192 620.978C292.837 621.623 293.711 621.987 294.623 621.988H416.366C417.278 621.984 418.151 621.62 418.795 620.975C419.439 620.331 419.803 619.458 419.808 618.547V600.942L291.182 600.942Z"
            fill="#CCCCCC"
          />
          <path
            id="Vector_3"
            d="M604.516 133.369H110.756C106.514 133.376 102.447 135.064 99.4475 138.064C96.4479 141.063 94.7596 145.13 94.7529 149.372V482.564C94.7569 486.807 96.4443 490.875 99.4445 493.875C102.445 496.875 106.513 498.562 110.756 498.567H604.516C608.759 498.562 612.827 496.875 615.827 493.875C618.828 490.875 620.515 486.807 620.519 482.564V149.372C620.512 145.13 618.824 141.063 615.824 138.064C612.825 135.064 608.758 133.376 604.516 133.369Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_4"
            d="M600.393 144.923H114.883C112.611 144.929 110.434 145.835 108.829 147.444C107.224 149.052 106.323 151.231 106.323 153.503V478.433C106.325 480.703 107.228 482.879 108.833 484.483C110.438 486.088 112.613 486.991 114.883 486.993H600.393C602.662 486.991 604.838 486.088 606.443 484.483C608.048 482.879 608.95 480.703 608.953 478.433V153.503C608.953 151.231 608.051 149.052 606.447 147.444C604.842 145.835 602.665 144.929 600.393 144.923V144.923Z"
            fill="white"
          />
          <path
            id="Vector_5"
            d="M168.135 468.366C168.146 474.726 167.013 481.036 164.79 486.995H114.881C112.613 486.991 110.438 486.089 108.834 484.484C107.23 482.88 106.327 480.706 106.323 478.437V415.664C109.111 415.213 111.932 414.987 114.757 414.988C121.766 414.987 128.708 416.368 135.184 419.05C141.661 421.732 147.545 425.664 152.502 430.621C157.459 435.578 161.39 441.462 164.073 447.939C166.755 454.415 168.136 461.356 168.135 468.366V468.366Z"
            fill="#6C63FF"
          />
          <path
            id="Vector_6"
            d="M608.953 153.503V436.323C571.596 447.212 531.709 445.633 495.329 431.826C458.95 418.018 428.06 392.734 407.336 359.8C386.612 326.867 377.182 288.078 380.475 249.306C383.767 210.534 399.602 173.891 425.583 144.923H600.393C602.665 144.929 604.842 145.835 606.447 147.444C608.051 149.052 608.953 151.231 608.953 153.503V153.503Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_7"
            d="M575.989 189.696H121.696C121.223 189.686 120.773 189.492 120.443 189.154C120.112 188.816 119.927 188.362 119.927 187.89C119.927 187.417 120.112 186.963 120.443 186.626C120.773 186.288 121.223 186.093 121.696 186.083H575.989C576.461 186.093 576.911 186.288 577.242 186.626C577.572 186.963 577.757 187.417 577.757 187.89C577.757 188.362 577.572 188.816 577.242 189.154C576.911 189.492 576.461 189.686 575.989 189.696V189.696Z"
            fill="#CACACA"
          />
          <path
            id="Vector_8"
            d="M152.62 176.859C158.467 176.859 163.207 172.013 163.207 166.036C163.207 160.058 158.467 155.212 152.62 155.212C146.773 155.212 142.032 160.058 142.032 166.036C142.032 172.013 146.773 176.859 152.62 176.859Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_9"
            d="M189.195 176.859C195.042 176.859 199.783 172.013 199.783 166.036C199.783 160.058 195.042 155.212 189.195 155.212C183.348 155.212 178.608 160.058 178.608 166.036C178.608 172.013 183.348 176.859 189.195 176.859Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_10"
            d="M225.77 176.859C231.617 176.859 236.358 172.013 236.358 166.036C236.358 160.058 231.617 155.212 225.77 155.212C219.923 155.212 215.183 160.058 215.183 166.036C215.183 172.013 219.923 176.859 225.77 176.859Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_11"
            d="M553.967 157.895H527.986C527.471 157.905 526.981 158.117 526.621 158.485C526.26 158.852 526.058 159.347 526.058 159.862C526.058 160.377 526.26 160.872 526.621 161.239C526.981 161.607 527.471 161.819 527.986 161.829H553.967C554.482 161.819 554.973 161.607 555.333 161.239C555.694 160.872 555.896 160.377 555.896 159.862C555.896 159.347 555.694 158.852 555.333 158.485C554.973 158.117 554.482 157.905 553.967 157.895Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_12"
            d="M553.967 165.279H527.986C527.471 165.289 526.981 165.501 526.621 165.869C526.26 166.237 526.058 166.731 526.058 167.246C526.058 167.762 526.26 168.256 526.621 168.624C526.981 168.992 527.471 169.203 527.986 169.214H553.967C554.482 169.203 554.973 168.992 555.333 168.624C555.694 168.256 555.896 167.762 555.896 167.246C555.896 166.731 555.694 166.237 555.333 165.869C554.973 165.501 554.482 165.289 553.967 165.279Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_13"
            d="M553.967 172.654H527.986C527.471 172.664 526.981 172.876 526.621 173.244C526.26 173.612 526.058 174.106 526.058 174.621C526.058 175.136 526.26 175.631 526.621 175.999C526.981 176.366 527.471 176.578 527.986 176.588H553.967C554.482 176.578 554.973 176.366 555.333 175.999C555.694 175.631 555.896 175.136 555.896 174.621C555.896 174.106 555.694 173.612 555.333 173.244C554.973 172.876 554.482 172.664 553.967 172.654Z"
            fill="#3F3D56"
          />
          <g id="Guy1">
            <path
              id="Vector_14"
              d="M311.975 64.7788C313.575 61.4054 306.161 54.5395 295.415 49.4431C284.67 44.3467 274.662 42.9498 273.062 46.3231C271.462 49.6964 278.876 56.5625 289.622 61.6588C300.367 66.7552 310.375 68.1521 311.975 64.7788Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_15"
              d="M400.658 99.5231C403.52 97.1263 399.652 87.7913 392.017 78.6727C384.382 69.5542 375.873 64.105 373.01 66.5018C370.147 68.8986 374.016 78.2336 381.651 87.3522C389.285 96.4707 397.795 101.92 400.658 99.5231Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_16"
              d="M341.027 118.602C364.812 118.602 384.094 99.32 384.094 75.5345C384.094 51.7491 364.812 32.4672 341.027 32.4672C317.241 32.4672 297.959 51.7491 297.959 75.5345C297.959 99.32 317.241 118.602 341.027 118.602Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_17"
              d="M359.255 109.334H346.172V132.776H359.255V109.334Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_18"
              d="M333.088 109.334H320.004V132.776H333.088V109.334Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_19"
              d="M348.352 137.137C354.374 137.137 359.255 135.307 359.255 133.048C359.255 130.79 354.374 128.96 348.352 128.96C342.331 128.96 337.449 130.79 337.449 133.048C337.449 135.307 342.331 137.137 348.352 137.137Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_20"
              d="M322.185 136.592C328.206 136.592 333.088 134.761 333.088 132.503C333.088 130.245 328.206 128.415 322.185 128.415C316.163 128.415 311.282 130.245 311.282 132.503C311.282 134.761 316.163 136.592 322.185 136.592Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_21"
              d="M327.504 21.3976C331.349 5.91062 348.324 -3.20315 365.419 1.04145C382.513 5.28605 393.253 21.2818 389.407 36.7688C385.562 52.2558 372.803 52.3058 355.709 48.0612C338.615 43.8165 323.658 36.8847 327.504 21.3976Z"
              fill="#6C63FF"
            />
            <path
              id="Vector_22"
              d="M334.588 80.8056C342.518 80.8056 348.947 74.377 348.947 66.447C348.947 58.5169 342.518 52.0883 334.588 52.0883C326.658 52.0883 320.229 58.5169 320.229 66.447C320.229 74.377 326.658 80.8056 334.588 80.8056Z"
              fill="white"
            />
            <path
              id="Vector_23"
              d="M333.687 77.0864C336.33 77.0864 338.473 74.9435 338.473 72.3002C338.473 69.6568 336.33 67.514 333.687 67.514C331.043 67.514 328.9 69.6568 328.9 72.3002C328.9 74.9435 331.043 77.0864 333.687 77.0864Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_24"
              d="M352.833 92.4433C353.78 97.6446 341.093 106.548 335.892 107.494C333.394 107.949 330.818 107.393 328.731 105.948C326.643 104.504 325.215 102.289 324.76 99.7912L324.757 99.7727C323.815 94.5706 327.837 92.7297 333.039 91.788C338.241 90.8462 351.891 87.2413 352.833 92.4433Z"
              fill="white"
            />
          </g>
          <g id="Guy2">
            <path
              id="Vector_25"
              d="M505.389 394.132C512.636 384.703 516.112 375.215 513.152 372.939C510.192 370.664 501.917 376.463 494.669 385.893C487.422 395.322 483.946 404.81 486.906 407.085C489.866 409.361 498.141 403.561 505.389 394.132Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_26"
              d="M544.836 425.602C568.622 425.602 587.904 406.32 587.904 382.534C587.904 358.749 568.622 339.467 544.836 339.467C521.051 339.467 501.769 358.749 501.769 382.534C501.769 406.32 521.051 425.602 544.836 425.602Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_27"
              d="M564.462 416.334H551.378V439.776H564.462V416.334Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_28"
              d="M538.294 416.334H525.211V439.776H538.294V416.334Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_29"
              d="M553.559 444.137C559.58 444.137 564.462 442.306 564.462 440.048C564.462 437.79 559.58 435.96 553.559 435.96C547.537 435.96 542.656 437.79 542.656 440.048C542.656 442.306 547.537 444.137 553.559 444.137Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_30"
              d="M527.391 443.592C533.413 443.592 538.294 441.761 538.294 439.503C538.294 437.245 533.413 435.415 527.391 435.415C521.37 435.415 516.488 437.245 516.488 439.503C516.488 441.761 521.37 443.592 527.391 443.592Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_31"
              d="M543.746 386.35C551.875 386.35 558.465 379.76 558.465 371.631C558.465 363.502 551.875 356.912 543.746 356.912C535.617 356.912 529.027 363.502 529.027 371.631C529.027 379.76 535.617 386.35 543.746 386.35Z"
              fill="white"
            />
            <path
              id="Vector_32"
              d="M542.746 383.538C545.456 383.538 547.652 381.341 547.652 378.631C547.652 375.922 545.456 373.725 542.746 373.725C540.036 373.725 537.839 375.922 537.839 378.631C537.839 381.341 540.036 383.538 542.746 383.538Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_33"
              d="M586.613 342.483C590.09 326.909 578.974 311.173 561.784 307.334C544.594 303.496 527.84 313.009 524.363 328.583C520.885 344.157 532.278 349.901 549.467 353.739C566.657 357.577 583.135 358.057 586.613 342.483Z"
              fill="#6C63FF"
            />
            <path
              id="Vector_34"
              d="M595.741 419.927C599.1 418.297 597.616 408.302 592.427 397.601C587.238 386.9 580.307 379.546 576.948 381.176C573.589 382.805 575.072 392.8 580.262 403.501C585.451 414.202 592.381 421.556 595.741 419.927Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_35"
              d="M565.991 398.071C565.991 402.286 555.137 410.61 543.094 410.61C531.051 410.61 519.759 398.743 519.759 394.528C519.759 390.313 531.051 395.346 543.094 395.346C555.137 395.346 565.991 393.856 565.991 398.071Z"
              fill="white"
            />
          </g>
          <g id="Guy5">
            <path
              id="Vector_36"
              d="M512.549 570.602C536.334 570.602 555.616 551.32 555.616 527.534C555.616 503.749 536.334 484.467 512.549 484.467C488.763 484.467 469.481 503.749 469.481 527.534C469.481 551.32 488.763 570.602 512.549 570.602Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_37"
              d="M506.007 561.334H492.923V584.776H506.007V561.334Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_38"
              d="M532.174 561.334H519.091V584.776H532.174V561.334Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_39"
              d="M512.685 592.704C513.65 590.662 510.017 586.922 504.572 584.351C499.127 581.78 493.931 581.351 492.967 583.393C492.003 585.435 495.635 589.174 501.08 591.746C506.525 594.317 511.721 594.746 512.685 592.704Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_40"
              d="M539.099 591.598C539.947 589.505 536.11 585.975 530.529 583.714C524.948 581.452 519.737 581.315 518.889 583.408C518.041 585.501 521.877 589.031 527.458 591.293C533.039 593.554 538.251 593.691 539.099 591.598Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_41"
              d="M470.772 487.483C467.294 471.909 478.41 456.173 495.601 452.334C512.791 448.496 529.545 458.009 533.022 473.583C536.5 489.157 525.107 494.901 507.917 498.739C490.727 502.577 474.249 503.057 470.772 487.483Z"
              fill="#6C63FF"
            />
            <path
              id="Vector_42"
              d="M563.744 509.857C568.043 497.382 568.353 486.174 564.437 484.825C560.521 483.475 553.86 492.494 549.561 504.969C545.262 517.445 544.951 528.652 548.868 530.002C552.784 531.351 559.444 522.332 563.744 509.857Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_43"
              d="M477.835 523.314C480.765 520.386 475.58 510.446 466.253 501.112C456.926 491.777 446.989 486.584 444.059 489.512C441.129 492.44 446.314 502.381 455.641 511.715C464.968 521.049 474.905 526.242 477.835 523.314Z"
              fill="#2F2E41"
            />
          </g>
          <g id="Guy4">
            <path
              id="Vector_44"
              d="M186.626 603.818C210.412 603.818 229.694 584.536 229.694 560.751C229.694 536.965 210.412 517.684 186.626 517.684C162.841 517.684 143.559 536.965 143.559 560.751C143.559 584.536 162.841 603.818 186.626 603.818Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_45"
              d="M180.084 594.551H167.001V617.992H180.084V594.551Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_46"
              d="M206.252 594.551H193.168V617.992H206.252V594.551Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_47"
              d="M177.904 622.354C183.925 622.354 188.807 620.523 188.807 618.265C188.807 616.007 183.925 614.176 177.904 614.176C171.882 614.176 167.001 616.007 167.001 618.265C167.001 620.523 171.882 622.354 177.904 622.354Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_48"
              d="M204.071 621.808C210.093 621.808 214.974 619.978 214.974 617.72C214.974 615.462 210.093 613.631 204.071 613.631C198.05 613.631 193.168 615.462 193.168 617.72C193.168 619.978 198.05 621.808 204.071 621.808Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_49"
              d="M144.849 520.7C141.372 505.126 152.488 489.389 169.678 485.551C186.868 481.712 203.622 491.226 207.1 506.8C210.577 522.373 199.185 528.117 181.995 531.956C164.805 535.794 148.327 536.273 144.849 520.7Z"
              fill="#6C63FF"
            />
            <path
              id="Vector_50"
              d="M241.849 571.802C243.786 568.14 235.901 560.17 224.237 554C212.573 547.83 201.547 545.796 199.61 549.458C197.673 553.12 205.559 561.09 217.223 567.26C228.887 573.43 239.912 575.463 241.849 571.802Z"
              fill="#2F2E41"
            />
          </g>
          <g id="Guy3">
            <path
              id="Vector_51"
              d="M180.651 344.301C191.396 339.204 198.81 332.338 197.21 328.965C195.61 325.592 185.603 326.988 174.857 332.085C164.112 337.181 156.698 344.047 158.298 347.42C159.898 350.794 169.906 349.397 180.651 344.301Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_52"
              d="M88.622 369.994C96.2567 360.875 100.125 351.54 97.2626 349.144C94.4 346.747 85.8903 352.196 78.2556 361.314C70.6209 370.433 66.7524 379.768 69.6149 382.165C72.4775 384.562 80.9873 379.113 88.622 369.994Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_53"
              d="M129.246 401.244C153.031 401.244 172.313 381.962 172.313 358.176C172.313 334.391 153.031 315.109 129.246 315.109C105.46 315.109 86.1786 334.391 86.1786 358.176C86.1786 381.962 105.46 401.244 129.246 401.244Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_54"
              d="M124.101 391.976H111.017V415.418H124.101V391.976Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_55"
              d="M150.268 391.976H137.185V415.418H150.268V391.976Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_56"
              d="M121.92 419.779C127.942 419.779 132.823 417.948 132.823 415.69C132.823 413.432 127.942 411.601 121.92 411.601C115.899 411.601 111.017 413.432 111.017 415.69C111.017 417.948 115.899 419.779 121.92 419.779Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_57"
              d="M148.088 419.234C154.109 419.234 158.991 417.403 158.991 415.145C158.991 412.887 154.109 411.056 148.088 411.056C142.066 411.056 137.185 412.887 137.185 415.145C137.185 417.403 142.066 419.234 148.088 419.234Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_58"
              d="M142.769 304.039C138.923 288.552 121.948 279.439 104.854 283.683C87.7599 287.928 77.0197 303.923 80.8653 319.41C84.7109 334.897 97.4693 334.947 114.563 330.703C131.658 326.458 146.614 319.526 142.769 304.039Z"
              fill="#6C63FF"
            />
            <path
              id="Vector_59"
              d="M135.685 363.447C143.615 363.447 150.043 357.019 150.043 349.089C150.043 341.159 143.615 334.73 135.685 334.73C127.754 334.73 121.326 341.159 121.326 349.089C121.326 357.019 127.754 363.447 135.685 363.447Z"
              fill="white"
            />
            <path
              id="Vector_60"
              d="M136.586 359.728C139.229 359.728 141.372 357.585 141.372 354.942C141.372 352.299 139.229 350.156 136.586 350.156C133.943 350.156 131.8 352.299 131.8 354.942C131.8 357.585 133.943 359.728 136.586 359.728Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_61"
              d="M126.677 379.004C126.452 380.241 126.473 381.51 126.738 382.739C127.003 383.967 127.508 385.132 128.223 386.166C128.939 387.199 129.85 388.082 130.907 388.763C131.963 389.444 133.144 389.911 134.38 390.136C135.617 390.361 136.886 390.341 138.115 390.075C139.344 389.81 140.508 389.305 141.542 388.59C142.576 387.875 143.458 386.963 144.14 385.906C144.821 384.85 145.287 383.67 145.512 382.433V382.433L145.516 382.414C146.458 377.212 142.435 375.371 137.233 374.43C132.031 373.488 127.619 373.802 126.677 379.004Z"
              fill="white"
            />
          </g>
          <path
            id="Vector_62"
            d="M688.87 621.982L22.1199 622.289C21.9632 622.29 21.808 622.259 21.6631 622.2C21.5183 622.14 21.3866 622.052 21.2757 621.942C21.1648 621.831 21.0767 621.7 21.0167 621.555C20.9566 621.41 20.9257 621.255 20.9257 621.098C20.9257 620.942 20.9566 620.787 21.0167 620.642C21.0767 620.497 21.1648 620.366 21.2757 620.255C21.3866 620.145 21.5183 620.057 21.6631 619.997C21.808 619.938 21.9632 619.907 22.1199 619.908L688.87 619.6C689.185 619.601 689.487 619.727 689.71 619.95C689.932 620.174 690.057 620.476 690.057 620.791C690.057 621.106 689.932 621.409 689.71 621.632C689.487 621.855 689.185 621.981 688.87 621.982V621.982Z"
            fill="#CACACA"
          />
        </g>
        <defs>
          <clipPath id="clip0_0_1">
            <rect width="690.061" height="622.354" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default EngineeringTeamAnim;
